import styled from '../../styled';
import { Button } from '@material-ui/core';

export const SecondaryCtaButton = styled<typeof Button, { target: string }>(
  Button
)`
  border: 0;
  color: #000;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: scale(1);

  &:disabled {
    color: white;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const CtaButton = styled<typeof Button, { target?: string }>(Button)`
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.1);
  background-color: ${(p) => p.theme.palette.primary.main};
  font-weight: 700;
  font-size: 15px;
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: scale(1);

  &:hover {
    background-color: ${(p) => p.theme.palette.primary.dark};
  }

  &:disabled {
    color: white;
  }
`;
