import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Author } from '../types/Author';
import { BlockContent, CloudinaryImage } from '../types/Article';
import styled from '../styled';
import { Calendar, MapPin } from 'react-feather';
import { orderBy, partition } from 'lodash-es';
import { Tooltip, Typography } from '@material-ui/core';
import { COLORS } from '../themes/color';

type IEvent = {
  title: string;
  startDate: string;
  endDate: string;
  slug: {
    current: string;
  };
  attendees: Author[];
  mainImage: CloudinaryImage;
  body: BlockContent;
  url: string;
  description: string;
  city: string;
  country: 'US' | 'GB' | 'DE';
};

function formatDates(startDate: string, endDate: string) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const start = new Date(startDate);
  const end = new Date(endDate);
  const startMonth = months[start.getMonth()];
  const startDay = start.getDate();

  if (!endDate) {
    return `${startMonth} ${startDay}`;
  }

  const endMonth = months[end.getMonth()];
  const endDay = end.getDate();

  if (start.getMonth() === end.getMonth()) {
    return `${startMonth} ${startDay}–${endDay}`;
  } else {
    return `${startMonth} ${startDay}–${endMonth} ${endDay}`;
  }
}

const EventWrapper = styled('div')`
  max-width: 500px;
  overflow: hidden;
  margin: 0 auto ${(props) => props.theme.spacing(6)}px;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.03);
  border: 1px solid #f9f9f9;

  @media (max-width: 532px) {
    max-width: auto;
    margin: 0 16px ${(props) => props.theme.spacing(6)}px;
  }
`;

const EventRow = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1fr;

  & > div {
    padding: ${(props) => props.theme.spacing(0)}px
      ${(props) => props.theme.spacing(2)}px
      ${(props) => props.theme.spacing(2)}px;
  }
`;

const CalendarBox = styled<'div', { isPast: boolean }>('div')`
  background-color: ${(p) => (p.isPast ? '#f5f5f5' : COLORS.PAPER_DARK)};
  color: ${(p) => (p.isPast ? COLORS.TEXT : '#000')};
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${(props) => props.theme.spacing(2)}px !important;
`;

const AttendeeOuterWrapper = styled('div')`
  display: flex;
  position: relative;
`;

const AttendeeWrapper = styled('div')`
  color: ${(p) => p.theme.palette.grey.A400};
  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: scale(1.15);
  }

  p a {
    border-bottom: 1px solid;
  }
`;

const AttendeeImage = styled('img')`
  width: 35px;
  border-radius: 100%;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  border: 3px solid white;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));

  @media (max-width: 800px) {
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

const Attendee = ({ attendee }: { attendee: Author }) => {
  const { name, image, linkedin } = attendee;
  const matches = new RegExp(/v[0-9]+\/(.+)/).exec(image.image.src);
  if (!matches) {
    return null;
  }
  const src = matches[1];
  const ASSET_URL = 'https://assets.affilimate.com';
  const toSize = (size: number, src: string) => {
    return `${ASSET_URL}/image/upload/w_${size}/${src}`;
  };

  const fullSrc = `${ASSET_URL}/${src}`;
  const webPSrc = src.replace('.png', '.webp').replace('.jpg', '.webp');
  const jpgSrc = src.replace('.png', '.jpg');

  return (
    <Tooltip title={`Meet with ${name}`} placement="top">
      <AttendeeWrapper>
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <picture>
            <source
              type="image/webp"
              srcSet={`${toSize(170, webPSrc)} 170w, ${toSize(
                85,
                webPSrc
              )} 85w`}
              sizes="100wv"
            />
            <source
              type="image/jpeg"
              srcSet={`${toSize(170, jpgSrc)} 170w, ${toSize(85, jpgSrc)} 85w`}
              sizes="100wv"
            />
            <AttendeeImage
              src={fullSrc}
              alt={name}
              loading="lazy"
              width="35"
              height="35"
            />
          </picture>
        </a>
      </AttendeeWrapper>
    </Tooltip>
  );
};

const nowInMs = Date.now().valueOf();

const toCountryName = (country: 'US' | 'GB' | 'DE') => {
  switch (country) {
    case 'US':
      return 'USA';
    case 'GB':
      return 'UK';
    case 'DE':
      return 'Germany';
  }
};

const Event = ({ event }: { event: IEvent }) => {
  const {
    title,
    startDate,
    endDate,
    city,
    country,
    url,
    attendees,
    description
  } = event;
  const end = new Date(endDate ? endDate : startDate).valueOf();
  const isPast = nowInMs > end;

  return (
    <EventRow>
      <div>
        <div>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <h3 style={{ marginBottom: '6px' }}>{title}</h3>
          </a>
          <div style={{ marginBottom: '12px' }}>
            <MapPin size={12} /> {city}, {toCountryName(country)}
          </div>
        </div>
        {description && (
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            {description}
          </Typography>
        )}
        {!isPast && (
          <AttendeeOuterWrapper>
            {attendees && attendees.map((a) => <Attendee attendee={a} />)}
          </AttendeeOuterWrapper>
        )}
      </div>
      <CalendarBox isPast={isPast}>
        <div>
          <Calendar size={24} />
          <Typography
            variant="body2"
            component="p"
            style={{ fontWeight: 'bold' }}
          >
            {formatDates(startDate, endDate)}
          </Typography>
        </div>
      </CalendarBox>
    </EventRow>
  );
};

const EventList = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityEvent {
        edges {
          node {
            slug {
              current
            }
            title
            url
            description
            startDate
            endDate
            city
            country
            attendees {
              name
              jobTitle
              linkedin
              slug {
                current
              }
              image {
                alt
                image {
                  width
                  height
                  src
                }
              }
            }
          }
        }
      }
    }
  `);

  const [upcomingEvents, pastEvents]: IEvent[][] = partition(
    data.allSanityEvent.edges.map((edge: any) => edge.node),
    (event: IEvent) => {
      const startDate = new Date(event.startDate).valueOf();
      return startDate > nowInMs;
    }
  );

  return (
    <div>
      <EventWrapper>
        {orderBy(upcomingEvents, (e) => new Date(e.startDate).valueOf()).map(
          (e) => {
            return <Event key={e.slug.current} event={e} />;
          }
        )}
      </EventWrapper>
      <EventWrapper style={{ opacity: 0.5 }}>
        {orderBy(pastEvents, (e) => -new Date(e.startDate).valueOf())
          .slice(0, 3)
          .map((e) => {
            return <Event key={e.slug.current} event={e} />;
          })}
      </EventWrapper>
    </div>
  );
};

export default EventList;
